import { FormikProps } from 'formik';
import { graphql, navigate } from 'gatsby';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Ui Kit
import Button from 'ui-kit/button/button';
import Link from 'ui-kit/link/link';

// Components
import { AddressVerificationAPIFailureModalContent } from 'components/add-address-form/AddAddressForm';
import { AddressVerificationForm } from 'components/address-verification-modal/AddressVerificationModal.component';
import { AutoRefillLearnMoreModal } from 'components/auto-refill-learn-more-modal';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import MembershipAutoRefillSection from 'components/membership-auto-refill-section/membership-auto-refill-section.component';
import MembershipRegistrationContactForm from 'components/membership-registration-contact-form/membership-registration-contact-form.component';
import MembershipRegistrationPersonalInformationForm from 'components/membership-registration-personal-information-form/membership-registration-personal-information-form.component';
import MembershipRegistrationShippingForm from 'components/membership-registration-shipping-form/membership-registration-shipping-form.component';
import WorkflowFooterLinks from 'components/workflow-footer-links/workflow-footer-links';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

// State
import { AccountErrorPayload } from 'state/account/account.reducers';
import { accountUninsuredRegister } from 'state/account/account.routines';
import {
    accountRegistrationFormValuesSelector,
    accountRegistrationUninsuredIsBusySelector
} from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    membershipRegistrationGetEthnicitiesRoutine,
    membershipRegistrationGetGendersRoutine,
    membershipRegistrationGetRacesRoutine
} from 'state/membership-registration/membership-registration.routines';
import { AddressParts } from 'state/usps/usps.reducers';

// Schema
import { CREATE_BIRDI_ACCOUNT_PERSONAL_DETAILS_SCHEMA } from 'schema/create-birdi-account-personal-info.schema';
import { CREATE_BIRDI_ACCOUNT_SHIPPING_INFO_SCHEMA } from 'schema/create-birdi-account-shipping-information.schema';
import { MEMBERSHIP_REGISTRATE_CONTACT_SCHEMA } from 'schema/membership/registrate-contact-form.schema';

// Type
import { MembershipRegistrationFormPersonalInfoProps } from 'types/membership';

// Util
import { getPhoneNumber } from 'util/globalVariables';
import { AddressValidateResponse } from 'util/usps';

// Hooks
import { useAddressVerification } from 'hooks/useAddressVerification';

// Styles
import './assist-others.scss';

const AssistOthers = ({ location, data }: { location: any; data: GatsbyTypes.AssistOthersDataQuery }): ReactElement => {
    // Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { verifyAddress } = useAddressVerification();

    // Refs
    const formRefPersonalInformation = useRef<FormikProps<MembershipRegistrationFormPersonalInfoProps>>(null);
    const formRefContact = useRef<FormikProps<MembershipRegistrationFormPersonalInfoProps>>(null);
    const formRefShipping = useRef<FormikProps<MembershipRegistrationFormPersonalInfoProps>>(null);

    // Selectors
    const formValues = useSelector(accountRegistrationFormValuesSelector);
    const isBusy = useSelector(accountRegistrationUninsuredIsBusySelector);

    // State
    const [isAutoRefill, setIsAutoRefill] = useState<boolean>(false);
    const [isAutoRefillAvailable, setIsAutoRefillAvailable] = useState<boolean>(false);

    // Footer links selector
    const workflowFooterLinks =
        data.allBlockContentRegistrationFlowFooterLinks?.edges?.[0]?.node?.field_registration_footer_link ?? [];

    useEffect(() => {
        dispatch(membershipRegistrationGetGendersRoutine.trigger());
        dispatch(membershipRegistrationGetRacesRoutine.trigger());
        dispatch(membershipRegistrationGetEthnicitiesRoutine.trigger());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAutoRefillModal = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                ctas: [
                    {
                        label: t('modals.autoRefillLearnMore.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        }
                    }
                ],
                bodyContent: <AutoRefillLearnMoreModal t={t} />
            })
        );
    }, [dispatch, t]);

    const verifyMemberAddress = () => {
        const address: AddressParts = {
            street1: formRefShipping.current?.values?.address1 ?? '',
            street2: formRefShipping.current?.values?.address2 ?? '',
            city: formRefShipping.current?.values?.city?.trim() ?? '',
            state: formRefShipping.current?.values?.state ?? '',
            zip: formRefShipping.current?.values?.zipcode ?? '',
            zip4: ''
        };

        const isPersonalInfoValid = CREATE_BIRDI_ACCOUNT_PERSONAL_DETAILS_SCHEMA.isValidSync(
            formRefPersonalInformation.current?.values
        );
        const isContactInfoValid = MEMBERSHIP_REGISTRATE_CONTACT_SCHEMA('US').isValidSync(
            formRefContact.current?.values
        );
        const isShippingAddressValid = CREATE_BIRDI_ACCOUNT_SHIPPING_INFO_SCHEMA.isValidSync(
            formRefShipping.current?.values
        );

        if (!isPersonalInfoValid || !isContactInfoValid || !isShippingAddressValid) {
            formRefPersonalInformation.current?.validateForm();
            formRefContact.current?.validateForm();
            formRefShipping.current?.validateForm();
            return;
        }

        verifyAddress({
            address,
            onSuccess: (validationResponse: AddressValidateResponse) => {
                if (validationResponse.responseCode === 'suggested') {
                    dispatch(
                        openModal({
                            showClose: false,
                            bodyContent: (
                                <AddressVerificationForm
                                    originalAddress={validationResponse.currentAddress!}
                                    correctedAddress={validationResponse.updatedAddress!}
                                    onSuccess={onVerificationAddressChoice}
                                    onClose={() => dispatch(closeModal({}))}
                                    className="membership-registration"
                                />
                            )
                        })
                    );
                }
            },
            onFailure: () => {
                dispatch(
                    openModal({
                        showClose: true,
                        className: 'prescription-modal',
                        bodyContent: <AddressVerificationAPIFailureModalContent translation={t} />,
                        ctas: [
                            {
                                label: t('modals.healthConditions.submit'),
                                variant: 'primary',
                                onClick: () => {
                                    dispatch(closeModal({}));
                                },
                                dataGALocation: 'AddressVerificationError'
                            }
                        ]
                    })
                );
            }
        });
    };

    const onVerificationAddressChoice = (address: AddressParts) => {
        if (address) {
            const valuesWithChosenAddress = {
                ...formValues,
                ...address,
                zipcode: (address.zipcode as string).length > 5 ? (address.zipcode as string).slice(0, 5) : address.zip,
                zip4: (address.zipcode as string).length > 5 ? (address.zipcode as string).slice(-4) : ''
            };
            dispatch(closeModal({}));

            handleJoinSubmit(valuesWithChosenAddress);
        }
    };

    // MARK: handleJoinSubmit
    const handleJoinSubmit = (verifiedAddress: AddressParts) => {
        const formData = {
            ...verifiedAddress,
            ...formRefPersonalInformation.current?.values,
            ...formRefContact.current?.values,
            ...formRefShipping.current?.values,
            autoRefill: isAutoRefill
        };

        if (formRefPersonalInformation.current) {
            formRefPersonalInformation.current.validateForm();
            formRefPersonalInformation.current.submitForm();
        }
        if (formRefContact.current) {
            formRefContact.current.validateForm();
            formRefContact.current.submitForm();
        }
        if (formRefShipping.current) {
            formRefShipping.current.validateForm();
            formRefShipping.current.submitForm();
        }

        Promise.all([
            formRefShipping.current?.submitForm(),
            formRefContact.current?.submitForm(),
            formRefPersonalInformation.current?.submitForm()
        ]).then(() => {
            if (
                formRefShipping.current?.isValid &&
                formRefContact.current?.isValid &&
                formRefPersonalInformation.current?.isValid
            ) {
                dispatch(
                    accountUninsuredRegister.trigger({
                        formData,
                        onSuccess: () => {
                            navigate('/confirm-email?flow=assist-others');
                        },
                        onFailure: (error: AccountErrorPayload) => {
                            const errorMessage = error.messageErrorText ? error.messageErrorText.toLowerCase() : '';
                            const existingAccountResponses = ['is already taken', 'username is invalid'];
                            const hasExistingAccount = existingAccountResponses.some((err) =>
                                errorMessage.includes(err)
                            );
                            let body = t('registration.errors.modals.default.body');

                            if (hasExistingAccount) {
                                body = t('registration.errors.modals.emailAlreadyOnFile.body');
                            }

                            dispatch(
                                openModal({
                                    showClose: false,
                                    type: 'danger',
                                    size: 'lg',
                                    headerContent: (
                                        <BirdiModalHeaderDanger
                                            headerText={t('registration.errors.modals.default.title')}
                                            icon="alert"
                                        />
                                    ),
                                    bodyContent: (
                                        <BirdiModalContentAlt
                                            subTitle={body}
                                            note={t('registration.errors.modals.default.note', {
                                                phoneNumber: getPhoneNumber({ isEnd: true })
                                            })}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.healthConditions.submit'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            },
                                            dataGALocation: 'AccountUninsuredRegisterError'
                                        }
                                    ]
                                })
                            );
                        }
                    })
                );
            }
        });
    };

    // MARK: Render
    return (
        <WorkflowLayout
            backgroundImage={data.backgroundImage}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('membership.assistOthers.eyebrowText') }}
            anonymousOnly={true}
        >
            <WorkflowLayoutFormWrapper
                className="assist-others-expedited-registration"
                currentFlow="assist-others"
                eyebrowText={t('membership.assistOthers.eyebrowText')}
                title={t('membership.assistOthers.profileTitle')}
            >
                <MembershipRegistrationPersonalInformationForm
                    initialValues={{
                        firstName: formValues?.firstName,
                        lastName: formValues?.lastName,
                        dateOfBirth: formValues?.dateOfBirth,
                        dobMonth: formValues?.dobMonth,
                        dobDay: formValues?.dobDay,
                        dobYear: formValues?.dobYear,
                        gender: formValues?.gender,
                        race: formValues?.race,
                        ethnicity: formValues?.ethnicity,
                        careProvider: formValues?.careProvider
                    }}
                    state={location.state}
                    onCancel={() => {
                        /* Do nothing */
                    }}
                    onSubmit={() => {
                        /* Do nothing */
                    }}
                    ref={formRefPersonalInformation}
                    validationSchema={CREATE_BIRDI_ACCOUNT_PERSONAL_DETAILS_SCHEMA}
                />

                <MembershipRegistrationContactForm
                    initialValues={{
                        phoneNumber: formValues?.phoneNumber,
                        username: formValues?.email,
                        password: formValues?.password,
                        passwordConfirm: formValues?.passwordConfirm
                    }}
                    onCancel={() => {
                        /* Do nothing */
                    }}
                    onSubmit={() => {
                        /* Do nothing */
                    }}
                    ref={formRefContact}
                    isUsernameReadOnly={false}
                />

                <MembershipRegistrationShippingForm
                    initialValues={{
                        address1: formValues?.address1,
                        address2: formValues?.address2,
                        city: formValues?.city,
                        state: formValues?.state,
                        zipcode: formValues?.zipcode
                    }}
                    onCancel={() => {
                        /* Do nothing */
                    }}
                    onSubmit={() => {
                        /* Do nothing */
                    }}
                    onStateChange={(state) => {
                        setIsAutoRefillAvailable(state === 'CA' ? false : true);
                    }}
                    ref={formRefShipping}
                    isZipBlocked={false}
                />

                <>
                    {isAutoRefillAvailable && (
                        <MembershipAutoRefillSection
                            handleChange={(isChecked) => setIsAutoRefill(isChecked)}
                            handleLearnMoreModal={handleAutoRefillModal}
                        />
                    )}
                </>

                <div className="assist-others-expedited-registration-ctas">
                    <Button
                        async
                        label={t('membership.assistOthers.ctas.continue')}
                        type="submit"
                        className="btn-personal-details-submit"
                        variant="primary"
                        onClick={verifyMemberAddress}
                        isBusy={isBusy}
                        disabled={isBusy}
                    />
                    <Link
                        to={'/get-started'}
                        label={t('membership.assistOthers.ctas.backLink')}
                        dataGALocation={'membership'}
                        className="btn-back-link"
                        variant="subtle"
                        external={false}
                    />
                </div>

                <div className="assist-others-expedited-registration-agreement">
                    <p>{t('membership.selectPlan.userAgreement')}</p>
                    <p>{t('membership.selectPlan.userAgreement1')}</p>
                    <p>{t('membership.selectPlan.userAgreement3')}</p>
                </div>
            </WorkflowLayoutFormWrapper>

            {/* Workflow links */}
            <WorkflowFooterLinks links={workflowFooterLinks as any} locationGAflow="assist others" />
        </WorkflowLayout>
    );
};

export default AssistOthers;

export const query = graphql`
    query AssistOthersData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
        allBlockContentRegistrationFlowFooterLinks(
            filter: { field_registration_flow_types: { eq: "assist_others_flow" } }
        ) {
            edges {
                node {
                    field_registration_footer_link {
                        title
                        uri
                    }
                }
            }
        }
    }
`;
